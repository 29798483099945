import React from "react";

import "../styles/basic.css";
// Importando las imágenes de ../imagenes/Editorial/
import img1 from "../imagenes/Editorial/1.jpg";
import img2 from "../imagenes/Editorial/3.png";
import img3 from "../imagenes/Editorial/4.jpg";
import img4 from "../imagenes/Editorial/DSC_7351.jpg";
import img5 from "../imagenes/Editorial/insta2.jpg";

const Editorial = () => {
  return (
    <>
      <ul className="fotos">
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="1" src={img1} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="2" src={img2} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="3" src={img3} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="4" src={img4} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="5" src={img5} />
        </li>
      </ul>
    </>
  );
};

export default Editorial;
