import React from "react";
import "../styles/basic.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBehance,
  faFacebook,
  faTiktok,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Social = () => {
  return (
    <div className="justify-content-center">
      <p className="social-container text-center">
        <a
          rel="noreferrer"
          href="https://www.behance.net/libertucha"
          target="_blank"
          className="icon social"
        >
          <FontAwesomeIcon icon={faBehance} size="1x" />
        </a>
        <a
          rel="noreferrer"
          href="https://www.facebook.com/Libertucha/"
          target="_blank"
          className="icon social"
        >
          <FontAwesomeIcon icon={faFacebook} size="1x" />
        </a>
        <a
          rel="noreferrer"
          href="https://www.instagram.com/libertucha/"
          target="_blank"
          className="icon social"
        >
          <FontAwesomeIcon icon={faInstagram} size="1x" />
        </a>
        <a
          rel="noreferrer"
          href="https://www.tiktok.com/@naguevonei"
          target="_blank"
          className="icon social"
        >
          <FontAwesomeIcon icon={faTiktok} size="1x" />
        </a>
      </p>
    </div>
  );
};

export default Social;
