import React from "react";

import "../styles/basic.css";
// Importando las imágenes de ../imagenes/B&W/
import img1 from "../imagenes/BandW/1.jpg";
import img2 from "../imagenes/BandW/2.jpg";
import img3 from "../imagenes/BandW/3.png";
import img4 from "../imagenes/BandW/4.jpg";
import img5 from "../imagenes/BandW/5.jpg";

const BW = () => {
  return (
    <>
      <ul className="fotos">
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="1" src={img1} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="2" src={img2} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="3" src={img3} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="4" src={img4} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="5" src={img5} />
        </li>
      </ul>
    </>
  );
};

export default BW;
