import React from "react";
// import PhotoCarrousel from "../components/PhotoCarrousel.jsx"
import "../styles/basic.css"
// import imgsHome from '../imagenes/home/imgsHome.js';
// import foto1 from "../imagenes/home/fran.png";
import foto2 from "../imagenes/home/benja.png";
import foto3 from "../imagenes/home/silla.jpg";
import foto4 from "../imagenes/home/agua.png";


// const descripciones = [
//     {
//     "titulo": "Vertigo",
//     "tecnica": "Collage analógico",
//     "medidas": "43x33cm",
//     "precio": "$75.000 (con marco) $70.000 (sin marco)",
//     "cantidad": "1 obra original",
//     "vendido": true
//     },
//     {
//     "titulo": "Cabezona",
//     "tecnica": "Collage analógico",
//     "medidas": "30x40cm",
//     "precio": "$70.000",
//     "cantidad": "1 obra original",
//     "vendido": true
//     }
//     ];

const Home = () => {
  //  console.log(imgsHome);
    return (
        <>
        <ul className="fotos">
        <li
          onClick={(e) => console.log(e)}
          className={"elemento imagen "}
        >
          <img className="foto" alt="0" src={foto3}></img>
        </li>
        <li
          onClick={(e) => console.log(e)}
          className={"elemento imagen "}
        >
          <img className="foto" alt="0" src={foto2}></img>
        </li>
        <li
          onClick={(e) => console.log(e)}
          className={"elemento imagen "}
        >
          <img className="foto" alt="0" src={foto4}></img>
        </li>
        {/* {fotos.map((foto) => (
          <li className="foto">
            <img className="foto" alt="" src={foto}></img>
          </li>
        ))} */}
      </ul>
        {/* <div className='container-horizontal'>
            <PhotoCarrousel fotos={imgsHome}/>
        </div> */}
        </>
    )
};

export default Home;