import React from "react";

import "../styles/basic.css";
import imgsHome from "../imagenes/home/imgsHome.js";
// import foto1 from "../imagenes/home/fran.png";

// import cuadros from "../imagenes/fashion/cuadros.jpg";
// import tanque from "../imagenes/fashion/tanque.jpg";
import abajo from "../imagenes/fashion/1.jpg";
import blurr from "../imagenes/fashion/2.png";
import clown from "../imagenes/fashion/3.jpg";
import naranja from "../imagenes/fashion/4.jpg";
import aqua from "../imagenes/fashion/52501106248_e29af4f7ae_o.png";
import cahquet from "../imagenes/fashion/DSC_9490.JPG";
// import crom from "../imagenes/fashion/crom.jpg";
// import flores from "../imagenes/fashion/flores.jpg";
// import tercio from "../imagenes/fashion/tercio.jpg";
// import asado from "../imagenes/fashion/asado.jpg";
// import dedo from "../imagenes/fashion/dedo.jpg";
// import cuero from "../imagenes/fashion/cuero.jpg";
// import asc from "../imagenes/fashion/asc.jpg";
// import chair from "../imagenes/fashion/chair.jpg";
// import dos from "../imagenes/fashion/dos.jpg";
// import gorro from "../imagenes/fashion/gorro.jpg";
// import pao from "../imagenes/fashion/pao.jpg";
// import rosa from "../imagenes/fashion/rosa.jpg";
// import balcony from "../imagenes/fashion/balcony.jpg";
// import duo from "../imagenes/fashion/duo.jpg";
// import insta from "../imagenes/fashion/insta.jpg";

// const descripciones = [
//     {
//     "titulo": "Vertigo",
//     "tecnica": "Collage analógico",
//     "medidas": "43x33cm",
//     "precio": "$75.000 (con marco) $70.000 (sin marco)",
//     "cantidad": "1 obra original",
//     "vendido": true
//     },
//     {
//     "titulo": "Cabezona",
//     "tecnica": "Collage analógico",
//     "medidas": "30x40cm",
//     "precio": "$70.000",
//     "cantidad": "1 obra original",
//     "vendido": true
//     }
//     ];

const Fashion = () => {
  console.log(imgsHome);
  return (
    <>
      <ul className="fotos">
        
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={naranja} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={abajo} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={blurr} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={clown} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={aqua} />
        </li>

        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="0" src={cahquet} />
        </li>
       
      </ul>
      {/* <div className='container-horizontal'>
            <PhotoCarrousel fotos={imgsHome}/>
        </div> */}
    </>
  );
};

export default Fashion;
