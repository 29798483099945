import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  /* </React.StrictMode> */
);

//   esta página fue programada por su señoría misma y única ZamuroAtómico
//   aka github.com/guaaan/
//   aka tu sueño virtual
//   para: <libertucha3

//               iWs                                 ,W[
//               W@@W.                              g@@[
//              i@@@@@s                           g@@@@W
//              @@@@@@@W.                       ,W@@@@@@
//             ]@@@@@@@@@W.   ,_______.       ,m@@@@@@@@i
//            ,@@@@@@@@@@@@W@@@@@@@@@@@@@@mm_g@@@@@@@@@@[
//            d@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
//           i@@@@@@@A*~~~~~VM@@@@@@@@@@Af~~~~V*@@@@@@@@@i
//           @@@@@A~          'M@@@@@@A`         'V@@@@@@b
//          d@@@*`              Y@@@@f              V@@@@@.
//         i@@A`                 M@@P                 V@@@b
//        ,@@A                   '@@                   !@@@.
//        W@P                     @[                    '@@W
//       d@@            ,         ]!                     ]@@b
//      g@@[          ,W@@s       ]       ,W@@s           @@@i
//     i@@@[          W@@@@i      ]       W@@@@i          @@@@i
//    i@@@@[          @@@@@[      ]       @@@@@[          @@@@@i
//   g@@@@@[          @@@@@!      @[      @@@@@[          @@@@@@i
//  d@@@@@@@          !@@@P      iAW      !@@@A          ]@@@@@@@i
// W@@@@@@@@b          '~~      ,Z Yi      '~~          ,@@@@@@@@@
// '*@@@@@@@@s                  Z`  Y.                 ,W@@@@@@@@A
//   'M@@@*f**W.              ,Z     Vs               ,W*~~~M@@@f
//     'M@    'Vs.          ,z~       'N_           ,Z~     d@P
//    M@@@       ~\-__  __z/` ,gmW@@mm_ '+e_.   __=/`      ,@@@@
//     'VMW                  g@@@@@@@@@W     ~~~          ,WAf
//        ~N.                @@@@@@@@@@@!                ,Z`
//          V.               !M@@@@@@@@f                gf-
//           'N.               '~***f~                ,Z`
//             Vc.                                  _Zf
//               ~e_                             ,gY~
//                 'V=_          -@@D         ,gY~ '
//                     ~\=__.           ,__z=~`
//                          '~~~*==Y*f~~~
   

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
