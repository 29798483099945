import React from "react";

import "../styles/basic.css";
// Importando las imágenes de ../imagenes/Food and people/
import img1 from "../imagenes/Food and people/1.JPG";
import img2 from "../imagenes/Food and people/2.JPG";
import img3 from "../imagenes/Food and people/3.JPG";
import img4 from "../imagenes/Food and people/4.png";
import img5 from "../imagenes/Food and people/5.png";

const FoodAndPeople = () => {
  return (
    <>
      <ul className="fotos">
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="1" src={img1} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="2" src={img2} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="3" src={img3} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="4" src={img4} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="5" src={img5} />
        </li>
      </ul>
    </>
  );
};

export default FoodAndPeople;
