import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Social from "./Social.js";
import "./sidebar.scss";

const sidebarNavItems = [
  {
    display: "Home",
    icon: <i className="bx bx-home"></i>,
    to: "/",
    section: "",
  },
  {
    display: "B&W",
    icon: <i className="bx bx-star"></i>,
    to: "/bw",
    section: "bw",
  },
  
  {
    display: "Collage",
    icon: <i className="bx bx-star"></i>,
    to: "/collage",
    section: "collage",
  },
  {
    display: "Cover Arts",
    icon: <i className="bx bx-star"></i>,
    to: "/for-other-artists",
    section: "for-other-artists",
  },
  {
    display: "Editorial",
    icon: <i className="bx bx-star"></i>,
    to: "/editorial",
    section: "editorial",
  },
  {
    display: "Fashion",
    icon: <i className="bx bx-star"></i>,
    to: "/fashion",
    section: "fashion",
  },
  {
    display: "Food & people",
    icon: <i className="bx bx-star"></i>,
    to: "/food-and-people",
    section: "food-and-people",
  },
  {
    display: "Projects",
    icon: <i className="bx bx-star"></i>,
    to: "/projects",
    section: "projects",
  },
  {
    display: "Shop",
    icon: <i className="bx bx-calendar"></i>,
    to: "/shop",
    section: "shop",
  },
];

const Sidebar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [stepHeight, setStepHeight] = useState(0);
  const sidebarRef = useRef();
  const indicatorRef = useRef();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const sidebarItem = sidebarRef.current.querySelector(
        ".sidebar__menu__item"
      );
      indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
      setStepHeight(sidebarItem.clientHeight);
    }, 50);
  }, []);

  // change active index
  useEffect(() => {
    const curPath = window.location.pathname.split("/")[1];
    const activeItem = sidebarNavItems.findIndex(
      (item) => item.section === curPath
    );
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
  }, [location]);

  return (
    <div className="sidebar">
      <div className="sidebar__logo-container">
        <div className="sidebar__logo">
          <a href="/" className="text-logo">
            <h1>LIBERTUCHA</h1>
          </a>
          <div className="sidebar__subtitle">Artista visual</div>
        </div>
      </div>
      <div ref={sidebarRef} className="sidebar__menu">
        <div
          ref={indicatorRef}
          className="sidebar__menu__indicator"
          style={{
            transform: `translateX(-50%) translateY(${
              activeIndex * stepHeight
            }px)`,
          }}
        ></div>
        <div className="sidebar__nav-items">
          {sidebarNavItems.map((item, index) => (
            <Link className="nav-item" to={item.to} key={index}>
              <div
                className={`sidebar__menu__item ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                <div className="sidebar__menu__item__text">{item.display}</div>
              </div>
            </Link>
          ))}
        </div>
        <Social></Social>
      </div>
    </div>
  );
};

export default Sidebar;
