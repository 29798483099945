import "../styles/estilo-fotos.css";
import React, { useState } from "react";
import VerMas from "../components/VerMas.js";
import foto1 from "../imagenes/vertigo.jpeg";
import foto2 from "../imagenes/cabezona.jpeg";
import foto3 from "../imagenes/arcangeldehueso.jpeg";
import foto4 from "../imagenes/glup.jpeg";
import foto5 from "../imagenes/manyerina.jpeg";
import foto6 from "../imagenes/pinchazo.jpeg";
import foto7 from "../imagenes/muerte.jpeg";
import foto8 from "../imagenes/justicia.jpeg";
import fotoexp from "../imagenes/obrs1.jpg";
import rosa from "../imagenes/rosa.jpeg";
import plata from "../imagenes/plata.jpeg";
import manos from "../imagenes/manos.jpg";
import boca from "../imagenes/boca.jpg";
import olvidame from "../imagenes/olvidame.jpg";

// function importAll(r) {
//   return r.keys().map(r);
// }

//let fotos = [foto1, foto2, foto3, foto4, foto5, foto6, foto7, foto8];
// TODO: agregar una lista de anversos y cambiar el valor de la foto al hacer click
// let anversos = []

// let arriba = 0;

const ObrasCarrousel = () => {
  const [arriba, setArriba] = useState(0);

  const elevateHandler = (foto) => {
    if (arriba === foto) {
      console.log("es igual");
      foto = null;
    }
    console.log(foto);
    setArriba(foto);
    return;
  };

  return (
    <div>
      <ul className="fotos">
        <li
          onClick={(e) => elevateHandler(14)}
          className={"elemento imagen " + (arriba === 14 ? "arriba" : "")}
        >
          <img className="foto" alt="0" src={boca}></img>
          <VerMas
            titulo="Olvídame"
            tecnica="Fotografía digital"
            medidas="60x90 cm"
            precio="$500.000 Impresion en Fine Art (no incluye marco)"
            cantidad="5"
          />
        </li>
        <li
          onClick={(e) => elevateHandler(13)}
          className={"elemento imagen " + (arriba === 13 ? "arriba" : "")}
        >
          <img className="foto" alt="0" src={olvidame}></img>
          <VerMas
            titulo="Olvídame"
            tecnica="Fotografía digital"
            medidas="60x90 cm"
            precio="$450.000 Impresion en Fine Art (no incluye marco)"
            cantidad="5"
          />
        </li>
        <li
          onClick={(e) => elevateHandler(12)}
          className={"elemento imagen " + (arriba === 12 ? "arriba" : "")}
        >
          <img className="foto" alt="0" src={manos}></img>
          <VerMas
            titulo="Llamada de emergencia "
            tecnica="Collage analógico"
            medidas="26x19 cm (sin marco) 43x33 (marco)"
            precio="$99.000"
            cantidad="1"
          />
        </li>
        <li
          onClick={(e) => elevateHandler(10)}
          className={"elemento imagen " + (arriba === 10 ? "arriba" : "")}
        >
          <img className="foto" alt="0" src={plata}></img>
          <VerMas
            titulo="Profecías"
            tecnica="Collage analógico"
            medidas="6x8,5cm "
            precio="$20.000"
            cantidad="1"
          />
        </li>
        <li
          onClick={(e) => elevateHandler(11)}
          className={"elemento imagen " + (arriba === 11 ? "arriba" : "")}
        >
          <img className="foto" alt="0" src={rosa}></img>
          <VerMas
            titulo="Las tres vidas de María"
            tecnica="Collage analógico"
            medidas="10x13cm "
            precio="$35.000"
            cantidad="1"
          />
        </li>
        <li
          onClick={(e) => elevateHandler(1)}
          className={"elemento imagen " + (arriba === 1 ? "arriba" : "")}
        >
          <img className="foto" alt="0" src={fotoexp}></img>
          <VerMas
            titulo="El puerto de los sueños"
            tecnica="Fotografía digital"
            medidas="110x73.3cm"
            precio="$287.000"
            cantidad="1"
          />
        </li>

        <li
          onClick={(e) => elevateHandler(2)}
          className={"elemento imagen " + (arriba === 2 ? "arriba" : "")}
        >
          <img className="foto" alt="0" src={foto1}></img>
          <VerMas
            titulo="Vertigo"
            tecnica="Collage analógico"
            medidas="43x33cm"
            precio="$75.000 (con marco) $70.000 (sin marco)"
            cantidad="1 obra original"
            vendido
          />
        </li>
        <li
          onClick={(e) => elevateHandler(3)}
          className={"elemento imagen " + (arriba === 3 ? "arriba" : "")}
        >
          <img className="foto" alt="" src={foto2}></img>

          <VerMas
            titulo="Cabezona"
            tecnica="Collage analógico"
            medidas="30x40cm"
            precio="$70.000"
            cantidad="1 obra original"
            vendido
          />
        </li>
        <li
          onClick={(e) => elevateHandler(4)}
          className={"elemento imagen " + (arriba === 4 ? "arriba" : "")}
        >
          <img className="foto" alt="" src={foto3}></img>
          <VerMas
            titulo="Arcángel de hueso"
            tecnica="Collage analógico"
            medidas="27x17cm"
            precio="$43.000 (con marco) $38.500 (sin marco)"
            cantidad="1 obra original"
            vendido
          />
        </li>
        <li
          onClick={(e) => elevateHandler(5)}
          className={"elemento imagen " + (arriba === 5 ? "arriba" : "")}
        >
          <img className="foto" alt="" src={foto4}></img>
          <VerMas
            titulo="Glup"
            tecnica="Collage analógico"
            medidas="65x40cm"
            precioDescuento="$50.000"
            descuentoPorcentaje="15% descuento"
            precio="$42.500"
            cantidad="1 obra original"
          />
        </li>
        <li
          onClick={(e) => elevateHandler(6)}
          className={"elemento imagen " + (arriba === 6 ? "arriba" : "")}
        >
          <img className="foto" alt="" src={foto5}></img>
          <VerMas
            titulo="Manyerina"
            tecnica="Collage analógico"
            medidas="25x30cm"
            precio="$40.000 (con marco) $38.000 (sin marco)"
            cantidad="1 obra original"
            vendido
          />
        </li>
        <li
          onClick={(e) => elevateHandler(7)}
          className={"elemento imagen " + (arriba === 7 ? "arriba" : "")}
        >
          <img className="foto" alt="5" src={foto6}></img>

          <VerMas
            titulo="Pinchazo al corazón"
            tecnica="Collage analógico"
            medidas="25x30cm"
            precio="$15.000 (con marco) $13.000 (sin marco)"
            cantidad="1"
            vendido
          />
        </li>
        <li
          onClick={(e) => elevateHandler(8)}
          className={"elemento imagen " + (arriba === 8 ? "arriba" : "")}
        >
          <img className="foto" alt="6" src={foto7}></img>
          <VerMas
            titulo="La muerte"
            tecnica="Collage digital"
            medidas="50x30cm"
            precio="$100.000"
            cantidad="3 ampliación"
            vendido
          />
        </li>
        <li
          onClick={(e) => elevateHandler(9)}
          className={"elemento imagen " + (arriba === 9 ? "arriba" : "")}
        >
          <img className="foto" alt="7" src={foto8}></img>
          <VerMas
            titulo="La justicia"
            tecnica="Collage digital"
            medidas="50x30cm"
            precio="$100.000"
            cantidad="3 ampliación"
          />
        </li>
        {/* {fotos.map((foto) => (
          <li className="foto">
            <img className="foto" alt="" src={foto}></img>
          </li>
        ))} */}
      </ul>
    </div>
  );
};

export default ObrasCarrousel;
