import React from 'react';

const AboutUs = () => {
    return (
        <>
            <h1>sobre nosotrxs</h1>
            <h1>sobre nosotrxs</h1>
            <h1>sobre nosotrxs</h1>
            <h1>sobre nosotrxs</h1>
            <h1>sobre nosotrxs</h1>
        </>
    )
};

export default AboutUs;
