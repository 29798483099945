import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./layouts/Home.jsx";
import Fashion from "./layouts/Fashion.jsx";
import BW from "./layouts/BW.jsx";
import Shop from "./layouts/shop.jsx"
import Gallery from "./layouts/Gallery.js"
import AboutUs from "./layouts/AboutUs";
import Sidebar from "./components/Sidebar.jsx";
import Collage from "./layouts/Collage.jsx"
import ContentForArtist from "./layouts/ContentForArtist.jsx"
import Editorial from "./layouts/Editorial.jsx"
import FoodAndPeople from "./layouts/FoodAndPeople.jsx"
import Projects from "./layouts/Projects.jsx"

function App() {
  return (
    <>
      <BrowserRouter>
        <Sidebar />
        <div className="body-container">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/fashion" element={<Fashion/>} />
            <Route path="/shop" element={<Shop/>} />
            <Route path="/bw" element={<BW/>} />
            <Route path="/collage" element={<Collage/>} />
            <Route path="/for-other-artists" element={<ContentForArtist/>} />
            <Route path="/editorial" element={<Editorial/>} />
            <Route path="/food-and-people" element={<FoodAndPeople/>} />
            <Route path="/projects" element={<Projects/>} />
            <Route path="/gallery" element={Gallery} />


            <Route path="/about" element={AboutUs} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
