import React from "react";

import "../styles/basic.css";
// Importando las imágenes de ../imagenes/B&W/
import img1 from "../imagenes/Projects/3.jpg";
import img2 from "../imagenes/Projects/37623063801_028daa0106_o.jpg";
import img3 from "../imagenes/Projects/foto3.jpg";
import img4 from "../imagenes/Projects/jpgpgpgp.jpg";
import img5 from "../imagenes/Projects/solounavez.png";
import img6 from "../imagenes/Projects/versionolvi1.jpg";

const Projects = () => {
  return (
    <>
      <ul className="fotos">
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="1" src={img1} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="2" src={img2} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="3" src={img3} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="4" src={img4} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="5" src={img5} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="6" src={img6} />
        </li>
      </ul>
    </>
  );
};

export default Projects;
