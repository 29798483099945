import React from 'react';

const gallery = () => {
    return (
        <>
            <h1>gallery </h1>
        </>
    );
};

export default gallery;
