import React from "react";
import ObrasCarrousel from "../components/ObrasCarrousel.jsx"
import "../styles/basic.css"


// const descripciones = [
//     {
//     "titulo": "Vertigo",
//     "tecnica": "Collage analógico",
//     "medidas": "43x33cm",
//     "precio": "$75.000 (con marco) $70.000 (sin marco)",
//     "cantidad": "1 obra original",
//     "vendido": true
//     },
//     {
//     "titulo": "Cabezona",
//     "tecnica": "Collage analógico",
//     "medidas": "30x40cm",
//     "precio": "$70.000",
//     "cantidad": "1 obra original",
//     "vendido": true
//     }
//     ];

const Shop = () => {
    return (
        <>
  
        <div className='container-horizontal'>
            <ObrasCarrousel/>
        </div>

        </>
    )
};

export default Shop;
