import React from "react";

import "../styles/basic.css";
// Importando las imágenes de ../imagenes/Cover art and content for artists/
import img1 from "../imagenes/Cover art and content for artists/1.jpg";
import img2 from "../imagenes/Cover art and content for artists/2.JPG";
import img3 from "../imagenes/Cover art and content for artists/3.PNG";
import img4 from "../imagenes/Cover art and content for artists/4.jpg";
import img5 from "../imagenes/Cover art and content for artists/5.JPG";

const ContentForArtist = () => {
  return (
    <>
      <ul className="fotos">
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="1" src={img1} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="2" src={img2} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="3" src={img3} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="4" src={img4} />
        </li>
        <li onClick={(e) => console.log(e)} className={"elemento imagen"}>
          <img className="foto" alt="5" src={img5} />
        </li>
      </ul>
    </>
  );
};

export default ContentForArtist;
