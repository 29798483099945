import "../styles/estilo-fotos.css";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const VerMas = ({ titulo, tecnica, medidas, precio, cantidad, vendido, precioDescuento, descuentoPorcentaje}) => {
  const [open, setOpen] = useState(false);

  let buttonLabel = "Ver más";

  let discountElement = null;

  if (!open) {
    buttonLabel = "Ver Más";
  } else {
    buttonLabel = "Uy, ver menos";
  }

  const conDescuento = (precioDescuento) =>{
    if (precioDescuento) {
      return (
        <>
          <br />
          <strong >Descuento: </strong> <em className="precio-descuento">{precioDescuento}</em> <em className="porcentaje-descuento">{descuentoPorcentaje}</em>
        </>
      );
    } else {
      return;
    }
  }
  const fueVendido = () => {
    if (vendido) {
      return (
        <>
          <br />
          <strong className="vendido">VENDIDO</strong>
        </>
      );
    } else {
      return;
    }
  };

  if(precioDescuento)
  {
    discountElement = conDescuento(precioDescuento)
  }

  return (
    <>
      <div className="p-2">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
          className="col-12 col-md-2 ver-mas"
          variant="light"
        >
          {buttonLabel}
        </Button>
        <Collapse in={open}>
          <div className="p-3 more-info">
            <p>
              <strong>Título:</strong> <em>{titulo}</em>
              <br />
              <strong>Técnica:</strong> <em>{tecnica}</em>
              <br />
              <strong>Medidas:</strong> <em>{medidas}</em>
              {discountElement}
              <br />
              <strong>Precio: </strong>
              <em>{precio}</em>
              <br />
              <em>
                <strong>1/{cantidad}</strong>
              </em>
              {fueVendido()}
              <br />
              <Button
                href="https://wa.me/56955117369/?text=Hola!Libertucha"
                variant="success"
                >
                Hola Libertucha! <FontAwesomeIcon icon={faWhatsapp} size="1x" />
              </Button>
            </p>
          </div>
        </Collapse>
        <div className="desktop-info">
          <p>
            <strong>Título:</strong> <em>{titulo}</em>
            <br />
            <strong>Técnica:</strong> <em>{tecnica}</em>
            <br />
            <strong>Medidas:</strong> <em>{medidas}</em>
            {discountElement}
            <br />
            <strong>Precio: </strong>
            <em>{precio}</em>
            <br />
            <em>
              <strong>1/{cantidad}</strong>
            </em>
            {fueVendido()}
          </p>
          <Button
            href="https://wa.me/56955117369/?text=urlencodedtext"
            variant="success"
          >
            Hola Libertucha! <FontAwesomeIcon icon={faWhatsapp} size="1x" />
          </Button>
        </div>
      </div>
    </>
  );
};

export default VerMas;
